import { createAction, createAsyncAction } from 'typesafe-actions';
import { Trail } from 'trail-map-components';
import { INotificationDialogData } from 'state/info/infoActions';
import BFGError from 'API/BFGError';
import { IEditableTrailProperties, IPOIMediaFiles } from 'interfaces/trail';

// Fetch user trails.
export const fetchUserTrailsAction = createAction('trails/fetchUserTrailsAction')();
export const fetchUserTrailsAsyncAction = createAsyncAction(
	'trails/fetchUserTrailsAsyncActionRequest',
	'trails/fetchUserTrailsAsyncActionSuccess',
	'trails/fetchUserTrailsAsyncActionFailure',
)<undefined, { trails: Trail[]}, Error>();

// Fetch user trail by id.
export const fetchUserTrailByIdAction = createAction('trails/fetchUserTrailByIdAction')<{ trailId: string; token?: string; userId: string }>();

export const fetchUserTrailByIdAsyncAction = createAsyncAction(
	'trails/fetchUserTrailByIdAsyncActionRequest',
	'trails/fetchUserTrailByIdAsyncActionSuccess',
	'trails/fetchUserTrailByIdAsyncActionFailure',
)<string, { trail:Trail | null;id:string }, string>();

// Delete user trail.
export const deleteUserTrailAction = createAction('trails/deleteUserTrailAction')<{trailId: string; onSuccess?: () => void}>();

export const deleteUserTrailAsyncAction = createAsyncAction(
	'trails/deleteUserTrailAsyncActionRequest',
	'trails/deleteUserTrailAsyncActionSucess',
	'trails/deleteUserTrailAsyncActionFailure',
)<string, string, string>();

// Add trail to my trails.
export const addTrailAction = createAction('trails/addTrailAction')<{ trail:Trail; notification: INotificationDialogData; shareToken?: string }>();

export const addTrailAsyncAction = createAsyncAction(
	'trails/addTrailAsyncActionRequest',
	'trails/addTrailAsyncActionSuccess',
	'trails/addTrailAsyncActionFailure',
)<{ creatingTrailFromTrailId: string }, { trail: Trail; creatingTrailFromTrailId: string }, { creatingTrailFromTrailId: string; error: BFGError }>();

// Save changes to my trail.
export const saveChangesToMyTrailAction = createAction('trails/saveChangesToMyTrailAction')<{ trail: Trail; notification?: INotificationDialogData; trailMediaFiles: File[]; deleteTrailMediaFileUrls: string[]; onSuccess: () => void }>();

export const saveChangesToMyTrailAsyncAction = createAsyncAction(
	'trails/saveChangesToMyTrailAsyncActionRequest',
	'trails/saveChangesToMyTrailAsyncActionSuccess',
	'trails/saveChangesToMyTrailAsyncActionFailure',
)<undefined, Trail, undefined>();

// Create trail from existing trip.
export const createTrailFromExistingTripAction = createAction('trails/createTrailFromExistingTripAction')<{ tripId: string; onSuccess: (trailId: string) => void }>();

export const createTrailFromExistingTripAsyncAction = createAsyncAction(
	'trails/createTrailFromExistingTripAsyncActionRequest',
	'trails/createTrailFromExistingTripAsyncActionSuccess',
	'trails/createTrailFromExistingTripAsyncActionFailure',
)<undefined, Trail, undefined>();

export const createTrailFromScratchAction = createAction('trails/createTrailFromScratch')<{ trailProps: IEditableTrailProperties; trailMediaFiles: File[]; onSuccess: (trailId: string) => void; onFailure: () => void; trailData: GeoJSON.FeatureCollection<GeoJSON.Geometry>; poiMediaFiles: { [poiId: string]: IPOIMediaFiles[] } }>();

export const createTrailFromScratchAsyncAction = createAsyncAction(
	'trails/createTrailFromScratchAsyncActionRequest',
	'trails/createTrailFromScratchAsyncActionSuccess',
	'trails/createTrailFromScratchAsyncActionFailure',
)<undefined, Trail, undefined>();

// Create trail from GPX file.
export const createTrailFromGPXFileAction = createAction('trails/createTrailFromGPXFileAction')<{ file: File; onSuccess: (trailId: string) => void }>();

export const getDownloadTrailGPXLinkAction = createAction('trails/getDownloadTrailGPXLink')<{trailId: string; includeWaypoints: boolean; onSuccess: (downloadUrl: string) => void; onFailure: () => void}>();
