import ConfigManager from 'API/ConfigManager';
import { requestAPI, authRequestAPI } from 'API/RequestAPI';
import { IFetchTrailsFilterBody, IEditableTrailProperties, IPOIMediaFiles } from 'interfaces/trail';
import { Trail } from 'trail-map-components';
import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';

export const getNearbyTrails = (filter: IFetchTrailsFilterBody, count = 500) => {
	const clientId = ConfigManager.getConfig().clientId;
	const includeData = false;
	const nearbyFiltersModel = JSON.stringify({ ...filter, includeData, count });
	const url = '/offroad/trail/nearby/filter';

	return authRequestAPI(url, 'POST', { client_id: clientId, 'Content-Type': 'application/json' }, nearbyFiltersModel);
};

export const getTrailById = (trailId: string, token?: string) => {
	const clientId = ConfigManager.getConfig().clientId;
	const query = queryString.stringify({ mergeDescription: false, token });

	const url = `/offroad/trail/${trailId}?${query}`;

	return authRequestAPI(url, 'GET', { client_id: clientId, 'Content-Type': 'application/json' });
};

export const getUserTrails = () => {
	const query = queryString.stringify({ includePublicTrails: true });
	const url = `/offroad/user/trail/list?${query}`;

	return authRequestAPI(url, 'GET', { 'Content-Type': 'application/json' });
};

export const deleteUserTrail = (trailId: string) => {
	const clientId = ConfigManager.getConfig().clientId;
	const url = `/offroad/user/trail/${trailId}`;

	return authRequestAPI(url, 'DELETE', { client_id: clientId, 'Content-Type': 'application/json' });
};

export const addTrailToMyTrails = (trail: Trail) => {
	const clientId = ConfigManager.getConfig().clientId;
	const trailModel = JSON.stringify(trail);
	const url = `/offroad/trail/copy/${trail.id}`;

	return authRequestAPI(url, 'POST', { client_id: clientId, 'Content-Type': 'application/json' }, trailModel);
};

export const searchTrails = (query: string, count?: number) => {
	const clientId = ConfigManager.getConfig().clientId;
	const url = '/offroad/trail/search';
	const includeData = false;
	const searchParametersModel = { query, count, includeData };

	return authRequestAPI(url, 'POST', { client_id: clientId, 'Content-Type': 'application/json' }, JSON.stringify(searchParametersModel));
};

export const editUserTrail = (trail: Trail) => {
	const url = `/offroad/user/trail/${trail.id}`;

	return authRequestAPI(url, 'PUT', { 'Content-Type': 'application/json' }, JSON.stringify(trail));
};

export const createUserTrailFromExistingTrip = (tripId: string) => {
	const url = '/offroad/user/trail-from-trip';

	return authRequestAPI(url, 'POST', { 'Content-Type': 'application/json' }, JSON.stringify({ tripId }));
};

export const uploadTrailImages = (trailId: string, files: File[], obstacleImages = false) => {
	const url = `/offroad/report/${trailId}/data`;
	const formData = new FormData();

	files.map((f) => {
		formData.append('files', f);
		if (f['tags']?.length > 0) {
			formData.append('tags', f['tags']);
		} else {
			formData.append('tags', '');
		}
		formData.append('customId', uuidv4());
		const isImage = f.type.includes('image/');
		const isVideo = f.type.includes('video/');

		if (isVideo) {
			const attachmentType = !obstacleImages ? 'video_user' : 'video_poi';

			formData.append('attachmentType', attachmentType);
		} else if (isImage) {
			const attachmentType = !obstacleImages ? 'image_user' : 'image_poi';

			formData.append('attachmentType', attachmentType);
		}
	});

	return authRequestAPI(url, 'POST', {}, formData);
};

export const deleteTrailMediaFile = (trailId: string, mediaUrl: string) => {
	const url = `/offroad/trail/${trailId}/media?url=${encodeURIComponent(mediaUrl)}`;

	return authRequestAPI(url, 'DELETE', { 'Content-Type': 'application/json' });
};

export const getTrailStyleConfiguration = () => {
	const clientId = ConfigManager.getConfig().clientId;

	return requestAPI('/offroad/trail/style-configuration', 'GET', { client_id: clientId, 'Content-Type': 'application/json' });
};

export const createTrailFromScratch = (trailProps: IEditableTrailProperties, trailData: GeoJSON.FeatureCollection<GeoJSON.Geometry>) => {
	const clientId = ConfigManager.getConfig().clientId;
	const trailModel = JSON.stringify({ ...trailProps, trailData });
	const url = '/offroad/user/trail/';

	return authRequestAPI(url, 'POST', { client_id: clientId, 'Content-Type': 'application/json' }, trailModel);
};

export const uploadPOIImages = (trailId: string, poiId: string, poiMediaFiles: IPOIMediaFiles[]) => {
	const query = queryString.stringify({ trailId, poiId });
	const url = `/offroad/report/data?${query}`;
	const formData = new FormData();

	poiMediaFiles.map(({ customId, file }) => {
		formData.append('files', file);
		formData.append('customId', customId);
		if (file.type.includes('image/')) {
			formData.append('attachmentType', 'image_poi');
		} else if (file.type.includes('video/')) {
			formData.append('attachmentType', 'video_poi');
		}
	});

	return authRequestAPI(url, 'POST', {}, formData);
};

export const uploadTrailFromGPXFile = (file: File) => {
	const url = '/offroad/user/trail/gpx';
	const formData = new FormData();

	formData.append('file', file);

	return authRequestAPI(url, 'POST', {}, formData);
};

export const getTrailSharedUrl = (trailId: string) => {
	const url = '/offroad/trail/share';
	const clientId = ConfigManager.getConfig().clientId;

	return authRequestAPI(url, 'POST', { client_id: clientId, 'Content-Type': 'application/json' }, JSON.stringify({ trailId }));
};

export const getTrailDownloadUrl = (trailId: string, includeWaypoints: boolean = false) => {
	const url = `/offroad/trail/${trailId}/export/gpx?includeWaypoints=${includeWaypoints}`;
	const clientId = ConfigManager.getConfig().clientId;

	return authRequestAPI(url, 'GET', { client_id: clientId, 'Content-Type': 'application/json' });
};
