import { all, call, takeLatest, put, takeEvery } from 'redux-saga/effects';
import {
	addTrailAction, addTrailAsyncAction,
	fetchUserTrailByIdAction, fetchUserTrailByIdAsyncAction,
	deleteUserTrailAction, deleteUserTrailAsyncAction,
	fetchUserTrailsAction, fetchUserTrailsAsyncAction,
	saveChangesToMyTrailAction, saveChangesToMyTrailAsyncAction,
	createTrailFromExistingTripAction, createTrailFromExistingTripAsyncAction,
	createTrailFromScratchAction, createTrailFromScratchAsyncAction,
	createTrailFromGPXFileAction, getDownloadTrailGPXLinkAction,
} from './trailsActions';
import { showFullScreenLoader } from 'state/loading/loadingActions';
import * as trailAPI from 'API/trailAPI';
import { Media, Trail } from 'trail-map-components';
import { clearNotificationInformation, setDialogInformation, setNotificationInformation } from 'state/info/infoActions';
import { ActionType } from 'typesafe-actions';
import BFGError from 'API/BFGError';
import { fetchTrailByIdAction } from 'state/explore/exploreActions';
import { COVER_IMAGE_TAGS } from 'utils/imageUtils';
import { AxiosResponse } from 'axios';

const fetchUserTrails = function* () {
	try {
		yield put(fetchUserTrailsAsyncAction.request());
		const userTrailsResponse: AxiosResponse = yield call(trailAPI.getUserTrails);
		const userTrails: Trail[] = userTrailsResponse.data;

		yield put(fetchUserTrailsAsyncAction.success({ trails: userTrails }));
	} catch (e) {
		if (e instanceof BFGError) {
			yield put(fetchUserTrailsAsyncAction.failure(e));
			yield put(setDialogInformation({ title: 'Error', message: e.message }));
		}
	}
};

const fetchUserTrailById = function* (action: ActionType<typeof fetchUserTrailByIdAction>) {
	const { token, trailId, userId } = action.payload;

	try {
		yield put(fetchUserTrailByIdAsyncAction.request(trailId));
		const userTrailResponse: AxiosResponse = yield call(trailAPI.getTrailById, trailId, token);
		const userTrail: Trail = userTrailResponse.data;

		if (userTrail.uploadedBy === userId) {
			yield put(fetchUserTrailByIdAsyncAction.success({ trail: userTrail, id: trailId }));
		} else {
			yield put(fetchUserTrailByIdAsyncAction.success({ trail: null, id: trailId }));
		}
	} catch (e) {
		yield put(fetchUserTrailByIdAsyncAction.failure(trailId));
	}
};

const deleteUserTrail = function* (action: ActionType<typeof deleteUserTrailAction>) {
	const { payload: { trailId, onSuccess } } = action;

	try {
		yield put(deleteUserTrailAsyncAction.request(trailId));
		yield call(trailAPI.deleteUserTrail, trailId);

		yield put(deleteUserTrailAsyncAction.success(trailId));
		onSuccess?.();
	} catch (e) {
		if (e instanceof BFGError) {
			yield put(deleteUserTrailAsyncAction.failure(trailId));
			yield put(setDialogInformation({ title: 'Error', message: e.message }));
		}
	}
};

const addTrail = function* (action: ActionType<typeof addTrailAction>) {
	const { payload: { trail: { id }, notification, shareToken } } = action;

	try {
		yield put(addTrailAsyncAction.request({ creatingTrailFromTrailId: id }));

		// Fetch the original trail.
		const trailResponse: AxiosResponse = yield call(trailAPI.getTrailById, id, shareToken);
		const trail: Trail = trailResponse.data;

		// Cleanup the Point features, remove id from properties.
		if (trail.trailData) {
			trail.trailData.features.forEach((feature) => {
				if (feature.geometry.type === 'Point' && feature.properties) {
					delete feature.properties.id;
				}
			});
		}

		// Set property public to false.
		trail.public = false;

		// Copy the original trail to my trails.
		const addTrailToMyTrailsResponse: AxiosResponse = yield call(trailAPI.addTrailToMyTrails, trail);
		const { id: newTrailId } = addTrailToMyTrailsResponse.data;

		// Fetch.
		const myTrailResponse: AxiosResponse = yield call(trailAPI.getTrailById, newTrailId);
		const myTrail: Trail = myTrailResponse.data;

		yield put(addTrailAsyncAction.success({ creatingTrailFromTrailId: id, trail: myTrail }));
		yield put(setNotificationInformation(notification));
	} catch (e) {
		if (e instanceof BFGError) {
			yield put(addTrailAsyncAction.failure({ creatingTrailFromTrailId: id, error: e }));
			yield put(setNotificationInformation({ title: 'Error', message: e.message, primaryButton: 'Close', handlePrimaryButtonClick: clearNotificationInformation }));
		}
	}
};

const saveChangesToMyTrail = function* (action: ActionType<typeof saveChangesToMyTrailAction>) {
	const { payload: { trail: editingTrail, notification, trailMediaFiles, deleteTrailMediaFileUrls, onSuccess } } = action;

	try {
		yield put(saveChangesToMyTrailAsyncAction.request());

		let media: Media[] = [];
		let newCoverInFiles = false;

		if (trailMediaFiles.length > 0) {
			const mediaResponse: AxiosResponse = yield call(trailAPI.uploadTrailImages, editingTrail.id, trailMediaFiles);

			media = mediaResponse.data;

			media = media.map((m, i) => {
				const tags: string[] | undefined = trailMediaFiles[i]['tags'];

				if (tags && tags?.length > 0) {
					newCoverInFiles = true;

					return { ...m, tags: COVER_IMAGE_TAGS };
				}

				return m;
			});

		}

		for (let i = 0; i < deleteTrailMediaFileUrls.length; i++) {
			yield call(trailAPI.deleteTrailMediaFile, editingTrail.id, deleteTrailMediaFileUrls[i]);
		}
		const newMedia = newCoverInFiles ? editingTrail.media.map((m) => ({ ...m, tags: m.tags?.filter((m) => !COVER_IMAGE_TAGS.includes(m)) })) : editingTrail.media;
		const deleted = [...newMedia, ...media].filter((m) => !deleteTrailMediaFileUrls.includes(m.url));

		yield call(trailAPI.editUserTrail, { ...editingTrail, media: deleted });

		const userTrailResponse: AxiosResponse = yield call(trailAPI.getTrailById, editingTrail.id);
		const userTrail: Trail = userTrailResponse.data;

		yield put(saveChangesToMyTrailAsyncAction.success(userTrail));
		if (notification) {
			yield put(setNotificationInformation(notification));
		}
		yield call(fetchTrailByIdAction, { trailId:userTrail.id });
		yield call(onSuccess);
	} catch (e) {
		if (e instanceof BFGError) {
			yield put(setDialogInformation({ title: 'Error', message: e.message }));
		}
		yield put(saveChangesToMyTrailAsyncAction.failure());
	}
};

const createTrailFromExistingTrip = function* (action: ActionType<typeof createTrailFromExistingTripAction>) {
	const { payload: { tripId, onSuccess } } = action;

	try {
		yield put(createTrailFromExistingTripAsyncAction.request());

		const createUserTrailResponse: AxiosResponse = yield call(trailAPI.createUserTrailFromExistingTrip, tripId);
		const { id } = createUserTrailResponse.data;

		yield call(fetchTrailByIdAction, { trailId: id });
		yield put(createTrailFromExistingTripAsyncAction.success(createUserTrailResponse.data));
		onSuccess(id);
	} catch (e) {
		if (e instanceof BFGError) {
			yield put(setDialogInformation({ title: 'Error', message: e.message }));
		}
		yield put(createTrailFromExistingTripAsyncAction.failure());
	}
};

const createTrailFromScratch = function* (action: ActionType<typeof createTrailFromScratchAction>) {
	const { payload: { trailProps, trailMediaFiles, onSuccess, onFailure, trailData, poiMediaFiles } } = action;

	try {
		yield put(createTrailFromScratchAsyncAction.request());

		const trailDataFeatures: GeoJSON.Feature[] = [];

		for (let i = 0; i < trailData.features.length; i++) {
			const feature = trailData.features[i];

			if (feature.geometry.type !== 'Point') {
				trailDataFeatures.push(feature);
			} else {
				const attachmentsIds = (poiMediaFiles[feature.properties!.id] ?? []).map(({ customId }) => customId);
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const { attachments, ...rest } = feature.properties!;

				trailDataFeatures.push({ ...feature, properties: { ...rest, attachmentsIds } });
			}
		}

		// Prepare POIs attachments.
		const trailDataPayload: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
			type: 'FeatureCollection',
			features: trailDataFeatures,
		};

		// Upload the trail.
		const createTrailFromScratchResponse: AxiosResponse = yield call(trailAPI.createTrailFromScratch, trailProps, trailDataPayload);
		const { id: trailId } = createTrailFromScratchResponse.data;

		// Upload the trail images.
		if (trailMediaFiles.length > 0) {
			yield call(trailAPI.uploadTrailImages, trailId, trailMediaFiles);
		}

		// Upload the POIs images.
		const poiFiles = Object.entries(poiMediaFiles);

		for (let i = 0; i < poiFiles.length; i++) {
			const poiId = poiFiles[i][0];
			const mediaFiles = poiFiles[i][1];

			if (mediaFiles.length > 0) {
				yield call(trailAPI.uploadPOIImages, trailId, poiId, mediaFiles);
			}
		}

		const trailResponse: AxiosResponse = yield call(trailAPI.getTrailById, trailId);
		const trailFromScratch: Trail = trailResponse.data;

		yield put(createTrailFromScratchAsyncAction.success(trailFromScratch));

		onSuccess(trailId);
	} catch (e) {
		if (e instanceof BFGError) {
			yield put(setDialogInformation({ title: 'Error', message: e.message }));
			yield put(createTrailFromScratchAsyncAction.failure());
			onFailure();
		}
	}
};

const createTrailFromGPXFile = function* (action: ActionType<typeof createTrailFromGPXFileAction>) {
	const { payload: { file, onSuccess } } = action;

	try {
		yield put(showFullScreenLoader(true));

		const createTrailFromGPXResponse: AxiosResponse = yield call(trailAPI.uploadTrailFromGPXFile, file);
		const { id } = createTrailFromGPXResponse.data;

		yield call(onSuccess, id);
	} catch (e) {
		if (e instanceof Error) {
			yield put(setDialogInformation({ title: 'Error', message: e.message }));
		}
	} finally {
		yield put(showFullScreenLoader(false));
	}
};

const getDownloadTrailGPXLink = function* (action: ActionType<typeof getDownloadTrailGPXLinkAction>) {
	const { payload: { trailId, includeWaypoints, onSuccess, onFailure } } = action;

	try {
		const getDownloadTrailGPXLinkResponse: AxiosResponse = yield call(trailAPI.getTrailDownloadUrl, trailId, includeWaypoints);
		const { downloadUrl } = getDownloadTrailGPXLinkResponse.data;

		yield call(onSuccess, downloadUrl);
	} catch (e) {
		yield call(onFailure);
	}
};

export const trailsSaga = function* () {
	yield all([
		takeLatest(fetchUserTrailsAction, fetchUserTrails),
		takeLatest(fetchUserTrailByIdAction, fetchUserTrailById),
		takeEvery(deleteUserTrailAction, deleteUserTrail),
		takeEvery(addTrailAction, addTrail),
		takeLatest(saveChangesToMyTrailAction, saveChangesToMyTrail),
		takeLatest(createTrailFromExistingTripAction, createTrailFromExistingTrip),
		takeLatest(createTrailFromScratchAction, createTrailFromScratch),
		takeLatest(createTrailFromGPXFileAction, createTrailFromGPXFile),
		takeLatest(getDownloadTrailGPXLinkAction, getDownloadTrailGPXLink),
	]);
};
